import fullPageInstance from './js/fullpage-init'
import buttons from './js/buttons.js'
import buildLogo from './js/8-ball'
import projectTitleDistance from './js/projects'
import { infoButton, updateInfoPadding } from './js/info'
import lozad from 'lozad'

// init lozad
const observer = lozad()
observer.observe()

const infoSection = document.getElementById('info')
buildLogo()
fullPageInstance(observer)

buttons()
infoButton(infoSection)
updateInfoPadding(infoSection)
// projectTitleDistance(projects)
window.addEventListener('resize', () => {
  setTimeout(() => {
    updateInfoPadding(infoSection)
  }, 500)
})

const topButton = document.getElementById('back-to-top')

topButton.addEventListener('click', function () {
  fullpage_api.moveTo(1)
})