export default function buttons () {
  const prevButtons = document.querySelectorAll('.prev')
  const nextButtons = document.querySelectorAll('.next')

  prevButtons.forEach(function (button) {
    button.addEventListener('click', function () {
      fullpage_api.setScrollingSpeed(0)
      fullpage_api.moveSlideLeft()
      fullpage_api.setScrollingSpeed(700)
    })
  })

  nextButtons.forEach(function (button) {
    button.addEventListener('click', function () {
      fullpage_api.setScrollingSpeed(0)
      fullpage_api.moveSlideRight()
      fullpage_api.setScrollingSpeed(700)
    })
  })
}
