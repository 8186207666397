export function updateInfoPadding (infoSection) {
  if (window.innerWidth > 600) {
    const firstImage = document.querySelector('.album-img').offsetTop
    infoSection.style.marginTop = firstImage + 'px'
  } else {
    infoSection.style.marginTop = 0
  }
}

export function infoButton (infoSection) {
  const infoButton = document.getElementById('info-button')
  const closeButton = document.getElementById('close-button')
  function toggleOpen () {
    infoSection.classList.toggle('open')
  }
  infoButton.addEventListener('click', function () {
    toggleOpen()
  })
  closeButton.addEventListener('click', function () {
    toggleOpen()
  })
}
