export default function projectTitleDistance (project) {
  if (window.innerWidth > 600) {
    return
  }

  const images = project.querySelectorAll('img')
  const imgHeights = []
  images.forEach(function (image) {
    const intrinsicHeight = image.attributes.height.value
    const intrinsicWidth = image.attributes.width.value
    const renderedHeight = window.innerWidth * intrinsicHeight / intrinsicWidth
    imgHeights.push(renderedHeight)
  })
  const max = imgHeights.reduce(function (a, b) {
    return Math.max(a, b)
  })
  images.forEach(function (image) {
    if (max > 0) {
      image.style.height = max + 'px'
    } else {
      image.style.height = 'auto'
    }
  })
  console.log('testing function')
}
