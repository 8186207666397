import fullpage from 'fullpage.js/dist/fullpage.extensions.min'
import resetSliders from './fullpage.resetSliders.min'
import projectTitleDistance from './projects'

export default function fullPageInstance (observer) {
  // eslint-disable-next-line no-new
  new fullpage('#fullpage', {
    // options here
    licenseKey: '6E87BB5C-11BA4195-B054E15C-AC52A33C',
    autoScrolling: true,
    resetSliders: true,
    lazyLoading: false,
    resetSlidersKey: ['b2ZmaWNlYmVuZ2Fuei5jb21fZGgwY21WelpYUlRiR2xrWlhKejhPRw==', 'YmVuZ2Fuei5vcmdfMVVoY21WelpYUlRiR2xrWlhKekVweA=='],
    touchSensitivity: 1,
    scrollingSpeed: 700,
    controlArrows: false,

    afterRender: function () {
      const projects = document.querySelectorAll('.fp-slidesContainer')
      projects.forEach(function (project) {
        projectTitleDistance(project)
      })
    },

    onLeave: function (origin, destination, direction) {
      fullpage_api.setScrollingSpeed(700)
      const infoSection = document.getElementById('info')
      if (destination.index === 0) {
        infoSection.classList.remove('hidden')
      } else {
        infoSection.classList.add('hidden')
      }

      if (destination) {
        const albumAfterDestination = destination.item.nextElementSibling
        if (albumAfterDestination) {
          const images = albumAfterDestination.querySelectorAll('.lozad')
          if (images.length > 0) {
            observer.triggerLoad(images[0])
          }
        }
      }

      projectTitleDistance(destination.item)
    },
    onSlideLeave: function (section, origin, destination, direction) {
      if (destination) {
        const slideAfterDestination = destination.item.nextElementSibling

        if (slideAfterDestination) {
          const slideAfterDestinationImage = slideAfterDestination.querySelector('.lozad')

          if (slideAfterDestinationImage) {
            observer.triggerLoad(slideAfterDestinationImage)
          }
        }
      }

      if (origin.isLast === true && direction === 'right') {
        if (section.isLast === true) {
          const firstSlides = document.querySelector('.fp-slidesContainer').children
          function indexInClass (collection, node) {
            for (let i = 0; i < collection.length; i++) {
              if (collection[i].classList.contains(node)) { return i }
            }
            return -1
          }
          fullpage_api.moveTo(1, indexInClass(firstSlides, 'active'))
          return false
        } else {
          fullpage_api.moveSectionDown()
          return false
        }
      }
      if (origin.isFirst === true && direction === 'left') {
        if (section.isFirst === true) {
          fullpage_api.moveTo(1, 1)
          return false
        } else {
          fullpage_api.moveSectionUp()
          return false
        }
      }
    }
  })
}
