import * as THREE from 'three'
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js'

export default function buildLogo () {
  const renderer = new THREE.WebGLRenderer({
    antialias: true,
    alpha: true
  })
  renderer.setSize(window.innerWidth, window.innerHeight)
  renderer.setPixelRatio(window.devicePixelRatio)
  renderer.setClearColor(0xffffff, 0)

  const sectionTag = document.querySelector('section')
  sectionTag.appendChild(renderer.domElement)

  const scene = new THREE.Scene()

  const camera = new THREE.PerspectiveCamera(50, window.innerWidth / window.innerHeight, 0.1, 10000)
  camera.position.z = -25.5

  const ambientLight = new THREE.AmbientLight(0xcccccc)
  scene.add(ambientLight)

  const directionalLight = new THREE.DirectionalLight(0xcccccc, 0.4)
  directionalLight.position.set(100, 200, -200)
  scene.add(directionalLight)

  const objLoader = new OBJLoader()
  const raycaster = new THREE.Raycaster()
  const mouse = new THREE.Vector2()

  const axis = new THREE.Vector3(0.1, 0.1, 0).normalize()

  // we are defining Logo as null until it gets loaded through the functiono below and then it is defined as the obj
  let logo = null

  const video = document.getElementById('video')
  const texture = new THREE.VideoTexture(video)
  texture.minFilter = THREE.LinearFilter
  const material = new THREE.MeshPhongMaterial({
    map: texture,
    specular: 0xffffff,
    shininess: 50
  })

  objLoader.load('assets/objects/Ben-Ganz_Logo_04.obj', function (obj) {
    obj.traverse(function (node) {
      if (node.isMesh) node.material = material
    })
    obj.position.z = 0
    logo = obj
    scene.add(obj)
    return obj
  })

  // Holding Camera Position for tweening ------------------------
  let currentX = 0
  let currentY = 0
  let aimX = 0
  let aimY = 0
  let splash = true

  let xSpeed = 0.05
  let ySpeed = 0.05

  function onMouseMove (event) {
    // calculate mouse position in normalized device coordinates
    // (-1 to +1) for both components

    mouse.x = (event.clientX / window.innerWidth) * 2 - 1
    mouse.y = -(event.clientY / window.innerHeight) * 2 + 1
  }

  const animate = function () {
    const diffX = aimX - currentX
    const diffY = aimY - currentY

    currentX = currentX + diffX * 0.1
    currentY = currentY + diffY * 0.1

    // if earth has been laoded then rotate it

    // check if state has been fulfilled, change behavior of animation
    if (logo) {
      logo.rotateOnAxis(axis, Math.PI * 0.003)

      if (splash === true) {
        logo.position.y = -14.5
        logo.position.x = -15.5
        logo.position.y += currentY
        logo.position.x += currentX
        document.addEventListener('mousemove', function (event) {
          aimX = ((window.innerWidth / 2) - event.pageX) * 0.0015
          aimY = ((window.innerHeight / 2) - event.pageY) * 0.002
        })
      } else {
        logo.position.x -= xSpeed
        logo.position.y += ySpeed
        if (window.innerWidth < 550) {
          if (logo.position.x < -28 || logo.position.x > -2) {
            xSpeed = -xSpeed
          }
          if (logo.position.y > 0 || logo.position.y < -30) {
            ySpeed = -ySpeed
          }
        } else {
          if (logo.position.x < -40 || logo.position.x > 10) {
            xSpeed = -xSpeed
          }
          if (logo.position.y > -5 || logo.position.y < -32) {
            ySpeed = -ySpeed
          }
        }
      }
    }

    raycaster.setFromCamera(mouse, camera)
    const intersects = raycaster.intersectObjects(scene.children, true)
    document.body.style.cursor = 'auto'
    for (let i = 0; i < intersects.length; i++) {
      document.body.style.cursor = 'pointer'
    }

    camera.lookAt(scene.position)
    renderer.render(scene, camera)

    window.addEventListener('mousemove', onMouseMove, false)
    requestAnimationFrame(animate)
  }
  animate()

  video.play()

  const mainContent = document.querySelector('.main-content')
  const infoSection = document.getElementById('info')
  const infoButton = document.getElementById('info-button')
  document.addEventListener('click', function () {
    splash = false
    video.style.position = 'fixed'
    mainContent.style.opacity = '1'
    mainContent.style.zIndex = 'unset'
    infoSection.style.display = 'block'
    infoButton.style.opacity = '1'
    video.style.display = 'none'
    video.pause()
  })

  window.addEventListener('resize', function () {
    camera.aspect = window.innerWidth / window.innerHeight
    camera.updateProjectionMatrix()
    renderer.setSize(window.innerWidth, window.innerHeight)
  })
}
